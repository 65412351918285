import React, { useState } from 'react';
import { Outlet, Link } from "react-router-dom";
import EmailForm from './EmailForm';

const Layout = () => {

  const [showNav, setShowNav] = useState(false);
  const [navActive, setNavActive] = useState('home');

  const navToggle = event => {
    event.preventDefault();
    setShowNav(!showNav);
  }

  const navClick = event => {
    let lnk = event.target.name;
    setNavActive(lnk);
    setShowNav(false);
    window.scrollTo(0, 0)
  }

  return (
    <>
      <header id="fh5co-header-section" className="sticky-banner">
        <div className="container">
          <div className="nav-header">
            <Link to="/" onClick={navToggle} className="js-fh5co-nav-toggle fh5co-nav-toggle dark"><i></i></Link>
            <h1 id="fh5co-logo"><Link to="/"><i className="icon-home"></i><span>One</span>Jubilee<span>Cottages</span></Link></h1>
            <nav id="fh5co-menu-wrap" role="navigation" className={showNav ? '' : 'hidden'}>
              <ul className="sf-menu" id="fh5co-primary-menu">
                <li className={navActive === 'home' ? 'active' : null}><Link to="/" onClick={navClick} name="home">Home</Link></li>
                <li className={navActive === 'contact' ? 'active' : null}><Link to="contact" onClick={navClick} name="contact">Contact</Link></li>
                <li className="active"><a href="mailto:stay@onejubileecottages.co.uk">stay@onejubileecottages.co.uk</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
      <div style={{height: "96px"}}></div>

      <Outlet />

      <footer>
        <div id="footer">
          <div className="container">
            <div className="row row-bottom-padded-md">
              <div className="col-md-3">
                <h3 className="section-title">About One Jubilee Cottages</h3>
                <p>An 1800's cottage available for holiday lets in Staffordshre. Rent a cottage for your holiday.</p>
              </div>
              <div className="col-md-3">
                <h3 className="section-title">Contact</h3>
                <p><a href="mailto:stay@onejubileecottages.co.uk">stay@onejubileecottages.co.uk</a></p>
                <p>1 Brewery Lane,<br />
                Great Haywood,<br />
                Staffordshire,<br />
                ST18 0SN.
                </p>
                <p><a href="https://maps.app.goo.gl/go9eTaP9j1kGWht8A" target="_blank" rel="noreferrer">Find us on Google Maps</a></p>
              </div>
              <div className="col-md-3 col-md-push-3">
                <h3 className="section-title">Newsletter</h3>
                <p>Subscribe for our newsletter</p>
                <EmailForm />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p className="fh5co-social-icons">
                  <a href="https://www.instagram.com/one_jubilee_cottages" target="_blank" rel="noreferrer"><i className="icon-instagram"></i></a>
                  <a href="https://maps.app.goo.gl/go9eTaP9j1kGWht8A" target="_blank" rel="noreferrer"><i className="icon-google2"></i></a>
                  <a href="https://maps.app.goo.gl/go9eTaP9j1kGWht8A" target="_blank" rel="noreferrer"><i className="icon-map3"></i></a>
                </p>
                <p>Copyright 2024 One Jubilee Cottages <a href="https://atomwebdesign.co.uk">Web Design and Development</a> by <a href="https://atomwebdesign.co.uk">Atom Web Ltd.</a> All Rights Reserved. Made using <a href="http://freehtml5.co/" target="_blank" rel="noreferrer">freehtml5.co</a></p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
};

export default Layout;
