import React, { useEffect } from 'react';

const Animate = ({className, children}) => {

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          let box = entry.target;
          if (!box.classList.contains('animated')) {
            box.classList.add(...['fadeInUp', 'animated']);
          }
        }
      });
    });

    let animatedBoxes = document.querySelectorAll('.animate-box');
    animatedBoxes.forEach(box => {
      observer.observe(box);
    });
  }, []);

  return (
    <div className={className + " animate-box"}>
      {children}
    </div>
  );
};

export default Animate;
