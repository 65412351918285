import React from 'react';
import Slider from "react-slick";
import Animate from "./components/Animate";

function App() {

  var sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    // dots: true,
    pauseOnHover: false,
  };

  return (
  <>
  <aside id="fh5co-hero" className="js-fullheight">
    <div className="slider-container js-fullheight">
      <Slider {...sliderSettings}>
        <div>
          <div className="container-fluid" style={{backgroundImage: "url(images/img_bg_1.jpg", height: "700px", backgroundSize: "cover"}}>
            <div className="row">
              <Animate className="col-md-4 col-md-offset-4 col-md-pull-4 js-fullheight slider-text">
                <div className="slider-text-inner">
                  <div className="desc">
                    <h1>Holiday Cottage in Staffordshire</h1>
                    <h2 className="price">Victorian Cottage</h2>
                    <p>Late 1800’s Victorian Cottage, built to celebrate the Queen’s Jubilee and fully renovated in 2023.</p>
                    <p className="details">
                      <span>3 Bedrooms</span>
                      <span>Hot Tub (on request)</span>
                      <span>Sleeps 6</span>
                      <span>Smart TVs</span>
                      <span>Towels and linen included</span>
                    </p>
                    { /* <!-- <p><a className="btn btn-primary btn-lg" href="#">Learn More</a></p> --> */ }
                  </div>
                </div>
              </Animate>
            </div>
          </div>
        </div>
        <div>
          <div className="container-fluid" style={{backgroundImage: "url(images/img_bg_3.jpg", height: "700px", backgroundSize: "cover"}}>
            <div className="row">
              <div className="col-md-4 col-md-offset-4 col-md-pull-4 js-fullheight slider-text">
                <div className="slider-text-inner">
                  <div className="desc">
                    <h1>Holiday Cottage in Staffordshire</h1>
                    <h2 className="price">Victorian Cottage</h2>
                    <p>Late 1800’s Victorian Cottage, built to celebrate the Queen’s Jubilee and fully renovated in 2023.</p>
                    <p className="details">
                      <span>3 Bedrooms</span>
                      <span>Hot Tub (on request)</span>
                      <span>Sleeps 6</span>
                      <span>Smart TVs</span>
                      <span>Towels and linen included</span>
                    </p>
                    { /* <!-- <p><a className="btn btn-primary btn-lg" href="#">Learn More</a></p> --> */ }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  </aside>
  <div id="fh5co-features">
    <div className="container">
      <div className="row">
        <Animate className="col-md-4">
          <div className="feature-left">
            <span className="icon">
              <i className="icon-map"></i>
            </span>
            <div className="feature-copy">
              <h3>Great Location</h3>
              <p>Located in the village of Great Haywood, on the edge of Cannock Chase with access to the M6 and close to Alton Towers.</p>
            </div>
          </div>
        </Animate>
        <Animate className="col-md-4">
          <div className="feature-left">
            <span className="icon">
              <i className="icon-wallet"></i>
            </span>
            <div className="feature-copy">
              <h3>Great Value</h3>
              <p>Sleep 6 for the price of a double room in a chain hotel.</p>
            </div>
          </div>
        </Animate>
        <Animate className="col-md-4">
          <div className="feature-left">
            <span className="icon">
              <i className="icon-chat"></i>
            </span>
            <div className="feature-copy">
              <h3>Get in Touch</h3>
              <p>You can email us any questions and we will get back to you as soon as possible.</p>
              <p><a href="mailto:stay@onejubileecottages.co.uk">stay@onejubileecottages.co.uk</a></p>
            </div>
          </div>
        </Animate>
      </div>
      <div className="row">
        <Animate className="col-md-4">
          <div className="feature-left">
            <span className="icon">
              <i className="icon-video"></i>
            </span>
            <div className="feature-copy">
              <h3>Kitchen YouTube Set</h3>
              <p>Do you need a Kitchen backdrop for a YouTube cooking video?</p>
            </div>
          </div>
        </Animate>
        <Animate className="col-md-4">
          <div className="feature-left">
            <span className="icon">
              <i className="icon-camera"></i>
            </span>
            <div className="feature-copy">
              <h3>Available for Photography</h3>
              <p>Do you need a set for professional photography?</p>
            </div>
          </div>
        </Animate>
        <Animate className="col-md-4">
          <div className="feature-left">
            <span className="icon">
              <i className="icon-instagram2"></i>
            </span>
            <div className="feature-copy">
              <h3>We're on Instagram</h3>
              <p>For up to date images, news and local events <a href="https://www.instagram.com/one_jubilee_cottages" target="_blank" rel="noreferrer">follow us!</a></p>
            </div>
          </div>
        </Animate>
      </div>
    </div>
  </div>

  <div id="fh5co-testimonial" style={{backgroundImage: "url(images/img_bg_2.jpg)"}}>
    <div className="container">
      <Animate className="row">
        <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
          <h2>Recent Reviews and Feedback</h2>
        </div>
      </Animate>
      <div className="row">
        <div className="col-md-4">
          <Animate className="box-testimony">
            <blockquote>
              <span className="quote"><span><i className="icon-quotes-right"></i></span></span>
              <p>&ldquo;The property was clean and well maintained. We turned up early and it wasn't a problem. Staff were so helpful and understanding with us having a little one.&rdquo;</p>
            </blockquote>
            <p className="author">Kelly via <a href="https://www.booking.com/hotel/gb/one-jubilee-cottages.html#tab-reviews" target="_blank" rel="noreferrer">booking.com</a></p>
          </Animate>
        </div>
        <div className="col-md-4">
          <Animate className="box-testimony">
            <blockquote>
              <span className="quote"><span><i className="icon-quotes-right"></i></span></span>
              <p>&ldquo;Thank you for a perfect stay, very comfortable, had all we needed for a family of 5.&rdquo;</p>
            </blockquote>
            <p className="author">Jerome <span className="subtext">Guest Book</span></p>
          </Animate>
        </div>
        <div className="col-md-4">
          <Animate className="box-testimony">
            <blockquote>
              <span className="quote"><span><i className="icon-quotes-right"></i></span></span>
              <p>&ldquo;Love the loft room as kid use it as a play room and was very easy to connect our Nintendo switch. Bed are very comfortable. Plenty of parking. The little shower is a great idea as made it easier to wash my youngest.&rdquo;</p>
            </blockquote>
            <p className="author">Susanna via <a href="https://www.booking.com/hotel/gb/one-jubilee-cottages.html#tab-reviews" target="_blank" rel="noreferrer">booking.com</a></p>
          </Animate>
        </div>
      </div>
    </div>
  </div>
  <div id="fh5co-properties" className="fh5co-section-gray">
    <div className="container">
      <div className="row">
        <Animate className="col-md-8 col-md-offset-2 text-center heading-section">
          <h3>Accomodation in Detail</h3>
          <p>Have a look at each of the rooms at One Jubilee Cottages in detail. This 1800's Victorian cottage has been renovated to a high standard with a cosy cottage feel.</p>
        </Animate>
      </div>
      <div className="row">
        <Animate className="col-md-4">
          <div className="property">
            <div className="fh5co-property" style={{backgroundImage: "url(images/rooms-outside.jpg)"}}>
              <ul className="list-details">
                <li>Private Enterance</li>
                <li>Large Drive</li>
                <li>Key Safe</li>
              </ul>
            </div>
            <div className="property-details">
              <h3>Outside</h3>
              <p>With a large drive and private entry.</p>
            </div>
          </div>
        </Animate>
        <Animate className="col-md-4">
          <div className="property">
            <div className="fh5co-property" style={{backgroundImage: "url(images/rooms-lounge.jpg)"}}>
              <span className="status">Ground floor</span>
              <ul className="list-details">
                <li>Sofa</li>
                <li>Smart TV</li>
                <li>Double Sided Fireplace</li>
              </ul>
            </div>
            <div className="property-details">
              <h3>Lounge</h3>
              <p>Large lounge with a 43" smart TV, access to kitchen diner and a double sided fireplace with wood burning stove.</p>
            </div>
          </div>
        </Animate>
        <Animate className="col-md-4">
          <div className="property">
            <div className="fh5co-property" style={{backgroundImage: "url(images/rooms-kitchen.jpg)"}}>
              <span className="status">Ground floor</span>
              <ul className="list-details">
                <li>Fridge</li>
                <li>Dishwasher</li>
                <li>Oven</li>
                <li>Island hob</li>
              </ul>
            </div>
            <div className="property-details">
              <h3>Kitchen</h3>
              <p>Fully equiped kitchen with crockery and cooking equipment.</p>
            </div>
          </div>
        </Animate>
      </div>
      <div className="row">
        <Animate className="col-md-4">
          <div className="property">
            <div className="fh5co-property" style={{backgroundImage: "url(images/rooms-dining.jpg)"}}>
              <span className="status">Ground floor</span>
              <ul className="list-details">
                <li>Dining Table</li>
                <li>Wood Burning Stove</li>
              </ul>
            </div>
            <div className="property-details">
              <h3>Dining Room</h3>
              <p>Open plan seating for 6 in the heart of the cottage.</p>
            </div>
          </div>
        </Animate>
        <Animate className="col-md-4">
          <div className="property">
            <div className="fh5co-property" style={{backgroundImage: "url(images/rooms-bathroom.jpg)"}}>
              <span className="status">First floor</span>
              <ul className="list-details">
                <li>Walk in Shower</li>
              </ul>
            </div>
            <div className="property-details">
              <h3>Bathroom</h3>
              <p>Fitted with walk-in rain shower and separate shower hose.</p>
              <p>Toilet and sink.</p>
            </div>
          </div>
        </Animate>
        <Animate className="col-md-4">
          <div className="property">
            <div className="fh5co-property" style={{backgroundImage: "url(images/rooms-bed1.jpg)"}}>
              <span className="status">First floor</span>
              <ul className="list-details">
                <li>Double Bed</li>
              </ul>
            </div>
            <div className="property-details">
              <h3>Bedroom 1</h3>
              <p>Double bedroom with bedside tables and storage.</p>
            </div>
          </div>
        </Animate>
      </div>
      <div className="row">
        <Animate className="col-md-4">
          <div className="property">
            <div className="fh5co-property" style={{backgroundImage: "url(images/rooms-bed2.jpg)"}}>
              <span className="status">First floor</span>
              <ul className="list-details">
                <li>Double Bed</li>
              </ul>
            </div>
            <div className="property-details">
              <h3>Bedroom 2</h3>
              <p>Double bedroom with bedside tables and storage.</p>
            </div>
          </div>
        </Animate>
        <Animate className="col-md-4">
          <div className="property">
            <div className="fh5co-property" style={{backgroundImage: "url(images/rooms-bed3.jpg)"}}>
              <span className="status">Second floor</span>
              <ul className="list-details">
                <li>2 Single Beds</li>
                <li>Desk</li>
                <li>Smart TV</li>
              </ul>
            </div>
            <div className="property-details">
              <h3>Bedroom 3</h3>
              <p>Accessed from Bedroom 1, this loft conversion has two single beds.</p>
            </div>
          </div>
        </Animate>
        <Animate className="col-md-4">
          <div className="property">
            <div className="fh5co-property" style={{backgroundImage: "url(images/rooms-garden.jpg)"}}>
              <span className="status">Ground floor</span>
              <ul className="list-details">
                <li>Hot tub available on request</li>
              </ul>
            </div>
            <div className="property-details">
              <h3>Courtyard garden</h3>
              <p>Walled garden with cottage garden planting, mature cherry blossum tree and seating.</p>
              <p>Hot tub available with advance notice. </p>
            </div>
          </div>
        </Animate>
      </div>
    </div>
  </div>
  <div id="fh5co-about" className="fh5co-agent">
    <div className="container">
      <div className="row">
        <Animate className="col-md-8 col-md-offset-2 text-center heading-section">
          <h3>Local Attractions</h3>
          <p>There are loads of attractions and places to visit in Staffordshire, and this central location with good transport links is perfect for your stay.</p>
        </Animate>
      </div>
      <div className="row">
        <Animate className="col-sm-3 text-center" data-animate-effect="fadeIn">
          <div className="fh5co-staff">
            <img className="img-responsive" src="images/attractions-shugborough.jpg" alt="Shugborough" />
            <h3>Shugborough</h3>
            <p>The National Trust Shugborough Estate is walking distance from the cottage.</p>
            <p className="fh5co-social-icons">
              <a href="https://www.nationaltrust.org.uk/visit/shropshire-staffordshire/shugborough-estate" target="_blank" rel="noreferrer"><i className="icon-link2"></i></a>
              <a href="https://www.instagram.com/ntshugborough" target="_blank" rel="noreferrer"><i className="icon-instagram"></i></a>
            </p>
          </div>
        </Animate>
        <Animate className="col-sm-3 text-center" data-animate-effect="fadeIn">
          <div className="fh5co-staff">
            <img className="img-responsive" src="images/attractions-alton-towers.jpg" alt="Alton Towers" />
            <h3>Alton Towers</h3>
            <p>Alton Towers theme park is less than 20 miles away.</p>
            <p className="fh5co-social-icons">
              <a href="https://www.altontowers.com/" target="_blank" rel="noreferrer"><i className="icon-link2"></i></a>
              <a href="https://www.facebook.com/158663267494588" target="_blank" rel="noreferrer"><i className="icon-facebook2"></i></a>
              <a href="https://www.youtube.com/user/officialaltontowers" target="_blank" rel="noreferrer"><i className="icon-youtube"></i></a>
            </p>
          </div>
        </Animate>
        <Animate className="col-sm-3 text-center" data-animate-effect="fadeIn">
          <div className="fh5co-staff">
            <img className="img-responsive" src="images/cannockchase.jpg" alt="Cannock Chase" />
            <h3>Cannock Chase</h3>
            <p>Area of Outstanding Natural Beauty only 20 minutes walk via the historic Essex Bridge.</p>
            <p className="fh5co-social-icons">
              <a href="https://www.forestryengland.uk/cannock-chase-forest" target="_blank" rel="noreferrer"><i className="icon-link2"></i></a>
            </p>
          </div>
        </Animate>
        <Animate className="col-sm-3 text-center" data-animate-effect="fadeIn">
          <div className="fh5co-staff">
            <img className="img-responsive" src="images/attractions-mag.svg" alt="Cannock Chase" />
            <h3>West Midlands Designer Outlet</h3>
            <p>65 designer brands at McArthurGlen Designer Outlet West Midlands, only 11 miles away.</p>
            <p className="fh5co-social-icons">
              <a href="https://www.mcarthurglen.com/en/outlets/uk/designer-outlet-west-midlands/" target="_blank" rel="noreferrer"><i className="icon-link2"></i></a>
              <a href="https://www.instagram.com/mcarthurglenwestmidlands/" target="_blank" rel="noreferrer"><i className="icon-instagram"></i></a>
            </p>
          </div>
        </Animate>
      </div>
    </div>
  </div>
  <div id="fh5co-contact" className="fh5co-contact">
    <div className="half-contact">
      <div className="container-fluid">
        <div className="row">
          <Animate className="col-md-12 col-md-offset-0 text-center heading-section">
            <h3>Ask anything, we're here to help</h3>
            <p>Please get in touch and we will answer as quickly as possible.</p>
          </Animate>
        </div>
      </div>
    </div>
    <div className="half-bg" style={{backgroundImage: "url(images/cover_bg_2.jpg)"}}></div>
  </div>
  </>
  );
}

export default App;
